// TO DO
// jQuery(document).ready(function($) {
//     $( window ).on("load",function() {

//         $('.spz-select2').select2();


//         // var observer = new MutationObserver(function(mutations) {
//         //     mutations.forEach(function(mutationRecord) {
//         //         console.log('style changed!');
//         //     });    
//         // });
    
//         // var target =  $('.media-modal').parent();
//         // observer.observe(target, { attributes : true, attributeFilter : ['style'] });

//       });
// });